import {reactive, toRefs, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import axios from '@/utils/axios'
import {message} from "ant-design-vue";


export interface IAuthorityState {
    scanCodeImg: String | null;
    timeoutId: any;
    isFirst: Boolean;
    channelSwitch: String | null;
}


export function authorityBill() {
    const route = useRoute();
    const router = useRouter();

    let stateRef = reactive(<IAuthorityState>{
        scanCodeImg: "",
        timeoutId: null,
        isFirst: true,
        channelSwitch:null,
    });

    let refData = toRefs(stateRef)


    let init = async () => {
        const macAd = <string>route.query.macAd;
        localStorage.setItem('macAd', macAd);
        await getAuthInfo()
    }

    let getAuthInfo = async () => {
        const r = <any>await axios.get(`/dwart/an_teaching/activating/activate/now/${route.query.macAd}`, {
            headers: {
                // @ts-ignore
                boxIp: route.query.ipAd
            }
        })
        if (!r.result) {
            message.destroy();
            if (stateRef.isFirst) message.warn('设备未激活');
            stateRef.isFirst = false;
            stateRef.scanCodeImg = r.data;
            stateRef.channelSwitch = r.channelSwitch;
        } else {
            localStorage.setItem('campusId', r.campusId);
            await router.push({path: '/login'})
        }
    }

    return {
        refData,
        getAuthInfo,
        init,

    };
}
